@tailwind base;
@tailwind components;
@tailwind utilities;

section {
  @apply mb-28;
}

.active-link {
  color: #285790
}

.jgubg{
  background-image: url("/src/assets/images/1680.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%; /* Set the height of the section to full viewport height */
  display: flex;
  justify-content: center;
  color: white;
  opacity: 0.85; 
}
.custom-size {
  width: 120px; 
  height: 120px; 
}
.form {
  border-color: #285790;
  border-radius: 800;
  background-color: #285790;
}
.discussform{
  border-color: #285790;
  --tw-ring-offset-color: #fff;
 --tw-ring-color: #285790;
}
.discussform:focus{
  border-color: #285790;
  --tw-ring-offset-color: #fff;
 --tw-ring-color: #285790;
}
.projectform {
    border-color: #285790;
    border-radius: 800;
    background-color: #285790;
  }
.form:hover {
  background-color: #1c3d67;
}

.buttondiscuss {
  background-color: #DC9970;
  border-color: #285790;
}

.buttons{
  border-color: #285790;
  color: #285790;
  border-radius: 800;
}
.buttons:hover{
  background-color: #1c3d67;
}

.header {
  @apply flex flex-col py-4 mb-4 justify-between;
}

.hero {
  @apply container px-5 flex flex-col mx-auto;
}


.portofolio-card:hover .img-hover {
  @apply opacity-25;
}

@media (min-width: 1024px) {
  .header {
    @apply container w-full flex-row py-8 px-10 mt-2 mb-4 mx-auto items-center content-center border-b border-gray-100;
  }

  .hero {
    @apply container flex-row mx-auto mt-8;
  }
}